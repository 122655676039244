<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <section class="invoice-add-wrapper mt-2">
      <b-row class="invoice-add">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="12" md="12">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12" xl="12" md="8">
            <validation-observer ref="invoiceRules">
              <b-form @submit.prevent>
                <b-card no-body class="invoice-preview-card">
                  <!-- Header -->
                  <b-card-body class="bg-light invoice-padding pb-0">
                    <div class=" d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                      <!-- Header: Left Content -->
                      <div>
                        <div class="logo-wrapper">
                          <img width="35%" height="50%" :src="require('@/assets/images/logo/logo2.png')" class="file-image" />
                        </div>

                        <p class="card-text mb-25">Harvard University <br /></p>
                        <p class="card-text mb-25">Scientific Instrument Machine Shop, 52 Oxford Street NW B1 152B, USA</p>
                        <p class="card-text mb-0">
                          +1 617-496-1351
                        </p>
                      </div>

                      <b-col cols="12" md="6">
                        <!-- Header: Right Content -->
                        <div class="invoice-number-date mt-md-0 mt-2 float-right">
                          <span class="title">
                            Invoice No
                          </span>
                          <div>
                            <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                              <b-form-input style="width:100%; " id="invoice-data-id" v-model="invoiceData.id" disabled />
                            </b-input-group>
                          </div>
                          <span class="title">
                            Date
                          </span>
                          <div>
                            <validation-provider name="Date" #default="{ errors }" rules="required">
                              <flat-pickr title="Date" style="width:100%; background-color:white" v-model="invoiceData.date" :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>

                          <span class="title">
                            Due Date:
                          </span>
                          <div>
                            <validation-provider name="Due Date" #default="{ errors }" rules="required">
                              <flat-pickr style="width:100%;  background-color:white" v-model="invoiceData.dueDate" :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" class="form-control invoice-edit-input" />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </div>
                      </b-col>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing" />

                  <!-- Invoice Client & Payment Details -->

                  <b-card-body class="invoice-padding pt-0">

                    <b-row class="invoice-spacing">
                      <!-- Col: Invoice To -->

                      <b-col cols="12" xl="6" class="mb-lg-1">
                        <h6 class="mb-2">
                          Type

                        </h6>


                        <!-- Select Client -->
                        <validation-provider name="Type" #default="{ errors }" rules="required">
                          <v-select v-model="invoiceData.type" :options="['Internal','External']" label="type" input-id="customer-data" :clearable="false" @input="typeSelected"> </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <!-- Selected Client -->
                        <div v-if="invoiceData.contact" class="mt-1">
                          <h6 class="mb-25">
                            {{ invoiceData.contact }}
                          </h6>
                          <b-card-text class="mb-25">
                            {{ invoiceData.email }}
                          </b-card-text>
                          
                        </div>
                      </b-col>

                
                    </b-row>



                    <b-row class="invoice-spacing" v-if="invoiceData.type == 'Internal'">
                      <!-- Col: Invoice To -->

                      <b-col cols="12" xl="6" class="mb-lg-1" >
                        <h6 class="mb-2">
                          Labs:

                        </h6>


                        <!-- Select Client -->
                        <validation-provider name="Lab" #default="{ errors }" rules="required">
                          <v-select v-model="invoiceData.contact" :options="dropLabs" label="approver" :reduce="(val) => val.approver" input-id="customer-data" :clearable="false" @input="labSelected"> </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                        <!-- Selected Client -->
                        <div v-if="invoiceData.contact" class="mt-1">
                          <h6 class="mb-25">
                            {{ invoiceData.contact }}
                          </h6>
                          <b-card-text class="mb-25">
                            {{ invoiceData.email }}
                          </b-card-text>
                          
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="invoice-spacing" v-else>
                        <b-col cols="12" xl="6" class="mb-lg-1" >
                        <h6 class="mb-2">
                          Customers

                        </h6>


                        <!-- Select Client -->
                        <validation-provider name="Contact" #default="{ errors }" rules="required">
                          <v-select v-model="invoiceData.contact" :options="dropCustomers" label="value" input-id="customer-data" :clearable="false" @input="clientSelected"> </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>

                  
                      </b-col>

                      <b-col cols="12" xl="6" class="mb-lg-1" >
                        <h6 class="mb-2">
                                  Charge String
                                </h6>
                                <validation-provider name="Charge String" #default="{ errors }" rules="required">
                                  <v-select v-model="invoiceData.poNumber" :options="poNumbers" label="digCode" :reduce="(val) => val.digCode" :clearable="true"> </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>
                          

                    
                
                    </b-row>
                
                  </b-card-body>

                  <!-- Items Section -->
                  <b-card-body class="invoice-padding form-item-section">
                    <!-- div ref="form" class="repeater-form" :style="{ height: trHeight }" -->
                    <div ref="form" class="repeater-form">
                      <b-row v-for="(item, index) in invoiceData.items" :key="index" ref="row" class="pb-2">
                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div class="d-none d-lg-flex">
                            <b-row class="flex-grow-1 px-1">
                              <!-- Single Item Form Headers -->
                              <b-col cols="12" lg="5">
                                Line Item
                              </b-col>
                            </b-row>
                            <div class="form-item-action-col" />
                          </div>

                          <!-- Form Input Fields OR content inside bordered area  -->
                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->

                          <div class="d-flex border rounded">
                            <b-row class="flex-grow-1 p-2">
                              <!-- Single Item Form Headers -->

                              <b-col cols="12" lg="4" v-if="invoiceData.type=='Internal'">
                                <span>
                                  Customer
                                </span>
                                <validation-provider name="Customer" #default="{ errors }" rules="required">
                                  <v-select v-model="item.customer" :options="dropCustomers" label="value" input-id="customer-data" :clearable="false"> </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>

                              <b-col cols="12" lg="8" v-if="invoiceData.type=='Internal'">
                                <span>
                                  Charge String
                                </span>
                                <validation-provider name="Charge String" #default="{ errors }" rules="required">
                                  <v-select :disabled="!item.customer" v-model="item.chargeString" :reduce="(val) => val.digCode" :options="getPoNumbers(item.customer)" label="digCode" :clearable="true"> </v-select>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>
                          



                              <b-col cols="12" lg="2">
                                <span>
                                  Item
                                </span>
                                <validation-provider name="Item" #default="{ errors }" rules="required">
                                  <b-form-input v-model="item.itemTitle" type="text" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>

                              <b-col cols="12" lg="4">
                                <span>
                                  Description
                                </span>
                                <validation-provider name="Description" #default="{ errors }" rules="required">
                                  <b-form-input v-model="item.description" type="text" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>

                              <b-col cols="12" lg="2">
                                <span>
                                  Cost
                                </span>
                                <validation-provider name="Cost" #default="{ errors }" rules="required">
                                  <!-- <b-form-input v-model="item.cost" type="number" /> -->
                                  <cleave id="cost" v-model="item.cost" class="form-control" :raw="true" :options="inputOptions.price" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>
                              <b-col cols="12" lg="2">
                                <span>
                                  Quantity
                                </span>
                                <validation-provider name="Quantity" #default="{ errors }" rules="required">
                                  <!-- <b-form-input v-model="item.qty" type="number" /> -->
                                  <cleave id="quantity" v-model="item.qty" class="form-control" :raw="true" :options="inputOptions.number" />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-col>

                              <b-col cols="12" lg="2">
                                <span>
                                  <strong>Price </strong>
                                </span>
                                <br />
                                <span>
                                  <strong>{{ formatPrice(item.cost * item.qty) }}</strong>
                                </span>

                                <br />
                              </b-col>
                            
                            </b-row>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm">
                      Add Item
                    </b-button>
                  </b-card-body>

                  <!-- Invoice Description: Total -->
                  <b-card-body class="invoice-padding pb-0">
                    <b-row>
                      <!-- Col: Sales Persion -->

                      <!-- Col: Total -->
                      <b-col cols="12" md="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                        <div class="invoice-total-wrapper">
                          <hr class="my-50" />
                          <div class="invoice-total-item">
                            <p class="invoice-total-title">
                              Total:
                            </p>
                            <p class="invoice-total-amount">{{ formatPrice(total) }}</p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing" />

                  <!-- Note -->
                  <b-card-body class="invoice-padding pt-0">
                    <span class="font-weight-bold">Note: </span>
                    <b-form-textarea v-model="invoiceData.note" />
                  </b-card-body>
                  <b-card-body class="invoice-padding pt-0">
                    <b-col cols="12">
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="saveInvoice" variant="primary" class="mr-1 float-right">
                        Save
                      </b-button>
                    </b-col>
                  </b-card-body>
                </b-card>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>

        <!-- Right Col: Card -->
      </b-row>
      <invoice-sidebar-add-new-customer />
    </section>
  </b-overlay>
</template>

<script>
import QuoteLogo from '@core/layouts/components/QuoteLogo.vue';
import { ref, onUnmounted } from '@vue/composition-api';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import invoiceStoreModule from '../invoiceStoreModule';
import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Cleave from 'vue-cleave-component';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    QuoteLogo,
    InvoiceSidebarAddNewCustomer,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  setup() {
    const INVOICE_STORE_MODULE_NAME = 'invoice';

    // Register module
    if (!store.hasModule(INVOICE_STORE_MODULE_NAME)) store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    const paymentMethods = ['Bank Account', 'PayPal', 'UPI Transfer'];

    return {
      paymentMethods,
    };
  },

  data() {
    var today = new Date();
    var dueDate = new Date();
    dueDate.setDate(today.getDate() + 30);

    var formattedToday = today.toISOString().split('T')[0];
    var formattedDueDate = dueDate.toISOString().split('T')[0];

    return {
      poData: null,
      changed: false,
      settingURL: store.state.app.settingURL,
      filter: null,
      filterOn: [],
      poNumbers: [],
      currencies: [],
      inputOptions: {
        number: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalMark: 'none',
          delimiter: 'none',
          numeralIntegerScale: 3,
        },

        price: {
          numeral: true,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: '.',
          delimiter: ',',
          numeralIntegerScale: 9,
        },

        phone: {
          phone: true,
        },
      },

      perPage: localStorage.getItem('customerShow') ?? 10,
      itemFormBlankItem: {
        cost: null,
        qty: null,
        description: null,
        inventoryId: null,
        vat: 20,
        main: false,
      },
      invoiceData: {
        items: [
          {
            customer:null,
            chargeString: null,
            date: null,
            itemTitle: null,
            cost: null,
            qty: null,
            description: null,
            vat: 20,
            main: false,
          },
        ],
        contact: null,
        poNumber: null,
        id: null,
        date: formattedToday,
        dueDate: formattedDueDate,
        note: null,
        status: null,
        email: null,
        tagNumber: null,
        projectId: null,
        type:'Internal'
      },
      required,
      email,
      dropCustomers: [],
      clientEmails:[],
      dropLabs: [],

      dropPoNumbers: [],
      timesheets: [],
      quotedHours: [],

      quoted: 0,
      current: 0,

      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      formShow: false,

      statusType: null,
      status: null,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralPositiveOnly: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
      required,
      email,
    };
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  computed: {
    totalTax() {
      var result = 0;

      this.invoiceData.items.forEach((element) => {
        result = result;
      });
      return result ? result : 0;
    },

    total() {
      var result = 0;
      this.invoiceData.items.forEach((element) => {
        result = result + element.cost * element.qty;
      });

      return result ? result : 0;
    },
  },

  watch: {
    'invoiceData.items': {
      handler: function(val, before) {
        if (val != null || val != 'None') {
          if (this.invoiceData.id != null && before[0].itemTitle != null && before[0].itemCategory != null && before[0].cost != null) {
            this.invoiceData.changed = true;
          }
        }
      },
    },

    'invoiceData.date': {
      handler: function(val, before) {
        var dueDate = new Date(val);
        dueDate.setDate(dueDate.getDate() + 30); // dueDate'e 30 gün ekleyin

        this.invoiceData.dueDate = dueDate.toISOString().split('T')[0]; // invoiceData.dueDate'i ayarlayın
      },
    },

    'invoiceData.cost_list': {
      handler: function(val, before) {},
      deep: true,
    },
  },

  methods: {
    formatPrice(value) {
      if (value != null) {
        // let val = (value / 1).toFixed(2).replace(',', '.');
        // return val;
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
      }
    },

    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      //this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.invoiceData.changed = true;
    },

    saveInvoice() {
      this.formShow = true;
      if (this.invoiceData.items.length > 0) {
        this.$refs.invoiceRules.validate().then((success) => {
          if (success) {
            if (router.currentRoute.params.id) {
              store
                .dispatch('invoice/updateInvoice', this.invoiceData)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Entry has been saved successfully',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  router.push({ name: 'invoice-preview', params: { id: response.data.id } });
                })
                .catch((e) => {
                  this.$swal({
                    title: 'Error',
                    text: 'Process Failed. Please try again or contact support.',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                  });
                });
            } else {
              store
                .dispatch('invoice/saveInvoice', this.invoiceData)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Entry has been saved successfully',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.formShow = false;

                  router.push({ name: 'invoice-preview', params: { id: response.data.id } });
                })
                .catch((e) => {
                  this.formShow = false;

                  this.$swal({
                    title: 'Error',
                    text: 'Process Failed. Please try again or contact support.',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                  });
                });
            }
          } else {
            this.formShow = false;
          }
        });
      } else {
        this.$swal({
          title: 'Error',
          text: 'Process Failed. Please try again or contact support.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });

        this.formShow = false;
      }
    },

    getByIdIncome() {
      if (router.currentRoute.params.id) {
        store
          .dispatch('invoice/fetchInvoice', { id: router.currentRoute.params.id })
          .then((response) => {
            if (response.status == 200) {
              const d = new Date(response.data.date);
              const f = new Date(response.data.dueDate);

              response.data.date = d;
              response.data.dueDate = f;
              this.invoiceData = response.data;
              this.poNumbers = this.dropPoNumbers.filter((x) => x.client == this.invoiceData.contact);
              this.poData = this.dropPoNumbers.find((x) => x.digCode == this.invoiceData.poNumber);
              this.formShow = false;
            } else {
              router.push({ name: 'error-404' });
            }
          })
          .catch((error) => {
            this.formShow = false;

            this.$swal({
              title: 'Error',
              text: 'Process Failed. Please try again or contact support.',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: 'OK',
            });

            router.push({ name: 'error-404' });
          });
      } else {
        this.formShow = false;
      }
    },

    customerDrop() {
      store
        .dispatch('invoice/customerDropInvoice')
        .then((response) => {
          this.dropCustomers = response.data.clients;
          this.dropLabs = response.data.labs;
          this.clientEmails = response.data.clientEmails;
          if (this.invoiceData.contact != null && this.invoiceData.type == 'External') {
            this.poNumbers = this.dropCustomers.find((x) => x.id == this.invoiceData.contact).poNumbers;
          }
          this.dropPoNumbers = response.data.poNumbers;
          this.timesheets = response.data.timesheets;
          this.quotedHours = response.data.quotedHours;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getPoNumbers(customer)
    {
      if(customer){
        return this.dropPoNumbers.filter((x) => x.client == customer);

      }
    },

    getPoNumbersExternal(customer)
    {
      if(customer){
        return this.dropPoNumbers.filter((x) => x.client == customer);

      }
    },

    typeSelected(){
      this.poData = null;
      this.invoiceData.items = [
        {
          customer:null,
          chargeString:null,
          poNumber: null,
          date: null,
          itemTitle: null,
          cost: null,
          qty: null,
          description: null,
        },
      ];
    },


    clientSelected() {
      this.poData = null;
      this.invoiceData.items = [
        {
          customer:null,
          chargeString:null,
          poNumber: null,
          date: null,
          itemTitle: null,
          cost: null,
          qty: null,
          description: null,
        },
      ];

      if(this.invoiceData.type == 'External'){
        this.invoiceData.email = this.clientEmails.filter((x) => x.contact == this.invoiceData.contact)[0].contactEmail;
        console.log(this.invoiceData.email)
      }

      this.poNumbers = this.dropPoNumbers.filter((x) => x.client == this.invoiceData.contact);
    },


    labSelected(){
        this.invoiceData.email = this.dropLabs.find((x) => x.approver == this.invoiceData.contact).email;
    
    },

    getCurrentHours() {
      var hours = 0;
      if (this.poData != null) {
        this.timesheets.forEach((element) => {
          if (element.jobId == this.poData.id) {
            hours += element.monday;
            hours += element.tuesday;
            hours += element.wednesday;
            hours += element.thursday;
            hours += element.friday;
            hours += element.saturday;
          }
        });
      }

      return hours;
    },

    getQuotedHours() {
      var hours = 0;
      if (this.poData != null) {
        var quotes = this.quotedHours.filter((x) => x.projectId == this.poData.id);
        quotes.forEach((element) => {
          hours += Number(element.hours);
        });
      }

      return hours;
    },

    poNumberSelected() {
     

      if (this.poData != null) {


      
      }
    },
  },

  created() {
    this.customerDrop();
    this.getByIdIncome();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
